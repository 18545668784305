.contact {
    display: flex;
    justify-content: space-between; /* Distributes space evenly between the elements */
    align-items: center; /* Aligns items to the top of the container */
    flex-wrap: wrap; /* Allows items to wrap onto the next line */
    padding: 20px;
    gap: 20px; /* Spacing between elements */
    background: antiquewhite;
    font-family:Arial, Helvetica, sans-serif;
}


.hours-and-methods-container, .map-container, .contact-form {
    flex: 1 1 30%; /* Allows each child to grow and shrink but base them on 30% */
    border-radius: 10px;
}

.hours-and-methods-container, .contact-form {
    padding: 10px;
}


.hours-and-methods-container{
    max-width: 400px;
    font-size: larger;
    text-align: center;
}

.store-hours-container, .contact-methods-container{
    padding: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    border: #795548;
    border-style: solid;
    border-radius: 50px;
}


.store-hours-container, .contact-form, .contact-methods-container {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
}

.contact-form{
    border: solid #795548;
}

form h2 {
    font-size: 30px;
    text-align: center;
}

.inputBox{
    margin-top: 20px;
    margin-right: 15px;
}


.inputBox .field{
    width: 95%;
    height: 50px;
    background: transparent;
    border: 2px solid #795548;
    outline: none;
    border-radius: 6px;
    padding: 15px;
    font-size: 16px;
    color: #333;
    margin-top: 8px;
}

.inputBox .field.mess {
    height: 200px;
    resize: none;
}

form button {
    width: 100%;
    height: 55px;
    background: #795548;
    border:none;
    border-radius: 6px;
    box-shadow: 0 0 10px rgba(0, 0,0 , .1);
    cursor: pointer;
    font-size: 24px;
    color: antiquewhite;
    font-weight: 600;
    margin-top: 25px;
    transition: 0.5s;
}

form button:hover{
    background: #412921;
}
