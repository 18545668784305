.footer{
  display: flex;
  flex-direction: row;
  background-color: #513329;
  color: antiquewhite;
  justify-content: space-between; /* Distributes space evenly between the elements */
  align-items: left; /* Aligns items to the top of the container */
  flex-wrap: wrap; /* Allows items to wrap onto the next line */
  padding: 20px;
  font-family:Arial, Helvetica, sans-serif;
  font-size: 20px;
  /* flex: 1 1 30%; Allows each child to grow and shrink but base them on 30% */
}

.storeHours, .footerLeft, .footerRight{
  flex: 1 1 30%; /* Allows each child to grow and shrink but base them on 30% */
  padding: 10px;
  text-align: center;

}

.storeHours{
   border-right: 5px solid antiquewhite;
   border-left: 5px solid antiquewhite;

}

.footerRight {
  display: flex;
  align-items: center;
  justify-content: center;

}

/* .socialMedia {
  display: flex;
  align-items: center;
  justify-content: center;
} */

.socialMedia svg {
  color: antiquewhite;
  font-size: 70px;
  cursor: pointer;
}
  