
.App {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  font-family: Arial, Helvetica, sans-serif;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-y: auto; /* Ensures full-page scrolling */
  display: flex;
  flex-direction: column;
}

