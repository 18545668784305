/* Main container styling */
.about {
  width: 100%;
  display: flex;
  min-height: 90vh; /* Ensures it takes at least 90% of the viewport */
  padding-top: 10px; /* Makes space for the navbar */
  padding-bottom: 80px; /* Makes space for the footer */
  justify-content: center;
  align-items: center;
  flex-direction: row; /* Start with a horizontal layout */
  background-color: antiquewhite;
  font-family: 'Times New Roman', Times, serif;
}

.aboutPhoto {
  flex: 1;
  background-size: contain; /* Ensures the image fits within the container without cropping */
  background-position: left center; /* Aligns the image to the left and vertically centers it */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  min-height: 400px; /* Minimum height to maintain visibility */
  width: 500%; /* You can adjust this width as needed */
  overflow: hidden; /* Ensures no part of the image spills outside the container */
  margin-left: 50px;
}

/* Styling for the text container */
.aboutText {
  flex: 1;
  margin: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center the text vertically within the container */
  font-size: larger;
}

/* Responsive behavior for smaller screens */
@media only screen and (max-width: 650px) {
  .about {
      flex-direction: column; /* Stack children vertically on smaller screens */
  }

  .aboutPhoto, .aboutText {
      
      margin: 0px; /* Provide some spacing */
      justify-content: center;
      align-items: center;
      text-align: center; /* Ensure text is centered for smaller screens */
  }

  .aboutText {
      background-color: #795548ba;
      
      color: white;
      padding: 20px;
      border-radius: 10px;
      width: 80%; /* Adjust width for smaller screens */
  }

  .aboutPhoto {
    width: 90%;
  }
}