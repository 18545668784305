.home {
    width: 100%;
    height: 90vh;
    display: flex;
    padding-top: 80px; /* Makes space for the navbar */
    padding-bottom: 80px; /* Makes space for the footer */
    justify-content: center;
    align-items: left;
    flex-direction: column;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: antiquewhite;
    font-family:'Times New Roman', Times, serif;
    /* text-shadow: #765245 5px, 0 15px; */
    position: relative; /* Added to position the overlay absolutely within */
 
  }

  .background-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(134, 133, 133, 0.18); /* Dark overlay with 20% opacity */
    z-index: 1; /* Should be below text content but above the background */
}


  .headerContainer {
    width: auto;
    margin-left: 50px;
    margin-top: -200px;
    z-index: 2;
    position: relative;
    text-shadow: #000000 3px 0 40px;
  }
  
  .headerContainer h1 {
    justify-content: center;
    align-items: center;
    font-size: 100px;
    height: 60px;
    font-weight: 400;
    color: antiquewhite;
  }
  
  .headerContainer p {
    padding: 0;
    font-size: 50px;
    color: antiquewhite;
    z-index: 2;
  }
  
  .headerContainer button {
    border: 2px solid antiquewhite;
    border-radius: 10px;
    cursor: pointer;
    justify-content: center;
    background-color: #795548;
    height: 70px;
    width: 200px;
    color: antiquewhite;
    font-size: 20px;
  }
  
  .headerContainer button:hover {
    background-color: #513329;
    cursor: pointer;
    transition: 0.3s ease-in;
  }
  body {
    overflow-y: auto; /* Allows full-page scrolling */
  }
  
  
  @media only screen and (max-width: 950px) {
    .home {
      justify-content: center;
      align-items: center;
    }

  
    .headerContainer {
      margin-left: 0px;
      border-radius: 10px;
      padding: 10px;
      width: 80%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background-color: #795548ba;

      color: white;
    }
  
    .headerContainer h1 {
      font-size: 9vw;
      max-width: 100%;
      height: auto;
      color: antiquewhite;
      text-shadow: none;
      text-align: center;
    }
  
    .headerContainer p {
      font-size: 7vw;
      max-width: 90%;
      height: auto;
      color: antiquewhite;
      text-shadow: none;
      text-align: center;
    }
  
    .headerContainer button {
      background-color: antiquewhite;
      color: #121619;
      font-family: 'Times New Roman', Times, serif;
      font-weight: 300;
      
      margin-bottom: 30px;
    }
  
    .headerContainer button:hover {
      background-color: rgb(225, 225, 225);
      color: #121619;
    }
  }